// src/styles/GlobalStyle.js
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html, body, #root {
    margin:5vh 0;
    height: 90vh;
    font-family: 'Arial', sans-serif;
  }

  body {
    font-family: "M PLUS Code Latin", monospace;
    font-optical-sizing: auto;
    font-weight: 100;
    font-style: normal;
    font-variation-settings:"wdth" 100;
    background-color: #121212;
    color: #ffffff;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
`;

export default GlobalStyle;

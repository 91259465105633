// src/pages/Home.js
import React from 'react';
import styled from 'styled-components';
import Banner from '../components/Banner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpotify, faApple, faYoutube } from '@fortawesome/free-brands-svg-icons';
import Background from '../assets/img/fotos/LP_gorros6.png'

const HomeContainer = styled.div`
  display: flex;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)),url(${Background});;
  background-size: cover;
  background-position: center;
  flex-direction: column;
  align-items: center;
  height: 100%;

`;

const IconsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 80%;
  align-items: center;
  margin-top: 1rem;
`;

const Icon = styled.a`
  font-size: 2rem;
  color: #ffffff;
  margin: 0 10px;
  transition: color 0.3s ease;

  &:hover {
    color: #CC5500;
  }
`;

const Home = () => {
  return (
    <HomeContainer>
      <Banner />
      <IconsContainer>
        <Icon href="https://open.spotify.com/intl-pt/artist/4alSdqMHQYDvGJc4kwzkKI" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faSpotify} />
        </Icon>
        <Icon href="https://music.apple.com/br/artist/gorros/1719736281" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faApple} />
        </Icon>
        <Icon href="https://www.youtube.com/@bandagorros" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faYoutube} />
        </Icon>
      </IconsContainer>
    </HomeContainer>
  );
};

export default Home;

// src/pages/About.js
import React, { useState } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import bandImage from '../assets/img/fotos/banner.jpg';
import generalLeeImage from '../assets/img/fotos/fotoshowgenerallee.jpeg';
import tainhaCoreImage from '../assets/img/fotos/fotoshowtainhacore.jpeg';
import bruxolicoImage from '../assets/img/fotos/fotoshowbruxolico.jpg';
import festival605Image from '../assets/img/fotos/fotoshow605.jpg';
import capaLiberdadeProvisoria from '../assets/img/fotos/LP_gorros6.png';

const SobreContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  color: #fff;
  background: #1b1b1b;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const TextSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: 0 auto;
  padding: 0 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0 1rem;
  }
`;

const TextContainer = styled.div`
  flex: 2;
  padding-left: 2rem;

  @media (max-width: 768px) {
    padding-left: 0;
    padding-top: 1rem;
  }
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin: 2rem 0;
  color: #fff;
  text-align: center;
`;

const ImageContainer = styled.div`
  flex: 1;
`;

const SlideImage = styled.img`
  width: 100%;
  height: 60vh;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const Text = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  text-align: justify;
`;

const CarouselContainer = styled.div`
  width: 60%;
  margin: 2rem auto;
`;

const CarouselSlide = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SlideMarker = styled.span`
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${props => props.active ? '#fff' : 'rgba(255, 255, 255, 0.5)'};
  margin: 0 5px;
  cursor: pointer;
`;

const SlideMarkersContainer = styled.div`
  text-align: center;
  margin-top: 1rem;
`;

const About = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    afterChange: current => setCurrentSlide(current),
  };

  const slides = [
    {
      image: generalLeeImage,
      text: "Em junho de 2016, fizeram sua estreia nos palcos, apresentando suas composições próprias no bar General Lee, em um evento com a banda Rinoceronte Negro.",
    },
    {
      image: tainhaCoreImage,
      text: "O sucesso dessa apresentação no General Lee rendeu à Gorros um convite para o Festival TainhaCore, realizado à beira-mar na praia dos Ingleses, em Florianópolis.",
    },
    {
      image: bruxolicoImage,
      text: "O evento foi um sucesso de público, motivando a banda a organizar o festival Bruxólico Fest, inspirado nos contos do folclorista Franklin Cascaes, em agosto de 2016.",
    },
    {
      image: bandImage,
      text: "Após uma série de apresentações bem-sucedidas, a banda decidiu focar na composição de músicas autorais. No final de 2016, Eduardo Tonon deixou a banda.",
    },
    {
      image: festival605Image,
      text: "Em março de 2017, retornaram aos palcos com uma nova formação no Festival de Arte 605, apresentando um repertório 100% autoral pela primeira vez. Devido a necessidades pessoais e profissionais dos integrantes, a banda entrou em hiato de abril de 2017 a fevereiro de 2023.",
    },
    {
      image: capaLiberdadeProvisoria,
      text: "A Banda atualmente está em fase de lançamento do seu último álbum 'Liberdade Provisória' e já está na fase de pré-produção do próximo álbum 'Êxodo'.",
    },
  ];

  return (
    <SobreContainer>
      <Title>Sobre a Banda</Title>
      <TextSection>
        <Text>
          A FloripoCuritibana Banda Gorros foi fundada em Florianópolis, em maio de 2016, por um grupo de amigos apaixonados por música e atentos observadores das diversas realidades sociais. Com uma proposta inovadora no cenário do pop rock nacional, a banda é formada por Carlos Felix, Fernando Felix, Ytor Flematti, Lucas Corteletti.
        </Text>
      </TextSection>

      <CarouselContainer>
        <Slider {...settings}>
          {slides.map((slide, index) => (
            <CarouselSlide key={index}>
              <ImageContainer>
                <SlideImage src={slide.image} alt={`Slide ${index + 1}`} />
              </ImageContainer>
              <TextContainer>
                <Text>
                  {slide.text}
                </Text>
              </TextContainer>
            </CarouselSlide>
          ))}
        </Slider>
        <SlideMarkersContainer>
          {slides.map((slide, index) => (
            <SlideMarker
              key={index}
              active={index === currentSlide}
              onClick={() => setCurrentSlide(index)}
            />
          ))}
        </SlideMarkersContainer>
      </CarouselContainer>
    </SobreContainer>
  );
};

export default About;

import React from 'react';
import styled from 'styled-components';

const GalleryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
`;

const GalleryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 1rem;
  width: 100%;
  max-width: 1200px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 0.5rem;
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }
`;

const VideoFrame = styled.iframe`
  width: 100%;
  height: 157px; /* Altura proporcional ao YouTube (16:9) */
  border: none;
`;

const GalleryComponent = () => {
  return (
    <GalleryContainer>
      <GalleryGrid>
        <VideoFrame src="https://www.youtube.com/embed/6g4Uf15jO60?si=4ujxTEQGIz8gAWP4" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></VideoFrame>
        <VideoFrame src="https://www.youtube.com/embed/Xo_t7Qj2JUA?si=KCV2z1retKbtkcpr" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></VideoFrame>
        <VideoFrame src="https://www.youtube.com/embed/1HFVKUg4Nw0?si=epoOhpMdDIxXOeyX" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></VideoFrame>
        <VideoFrame src="https://www.youtube.com/embed/SWOxS_CA-4U?si=PyT-SroaiZbU3JqL" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></VideoFrame>
      </GalleryGrid>
    </GalleryContainer>
  );
};

export default GalleryComponent;

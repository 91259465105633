import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import logoBanda from '../../assets/img/logo/GORROS_05.png';

const HeaderContainer = styled.header`
  background-color: #232323;
  padding: 0.5vh 1vw;
  text-align: center;
  position: fixed;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 0.5rem;
  }
`;

const LogoContainer = styled.div`
  padding: 0vh 1vw;
`;

const NavContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
`;

const LogoBanda = styled.img`
  height: 8vh;
  width: 23vh;
`;

const Navigation = styled.nav`
  a {
    margin: 0 15px;
    color: #ffffff;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
    &:hover {
      color: #CC5500;
    }
  }
`;

const HeaderDesktop = () => {
  return (
    <HeaderContainer>
      <LogoContainer>
        <Link to="/">
          <LogoBanda src={logoBanda} />
        </Link>
      </LogoContainer>

      <NavContainer>
        <Navigation>
          <Link to="/about">Sobre</Link>
          <Link to="/music">Músicas</Link>
          <Link to="/tour">Tour</Link>
          <Link to="/gallery">Galeria</Link>
          <Link to="/rider">Rider Técnico</Link>
          <Link to="/contact">Contato</Link>
        </Navigation>
      </NavContainer>
    </HeaderContainer>
  );
}

export default HeaderDesktop;

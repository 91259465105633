// components/Footer.js
import React from 'react';
import styled from 'styled-components';
import MusicPlayer from '../components/MusicPlayer';
import CapaAlbum from '../assets/img/fotos/LP_gorros6.png';

const FooterContainer = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #222;
  padding: 1rem;
  width: 100vw;
  text-align: center;
  position: fixed;
  bottom: 0;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const Footer = () => {


  return (
    <FooterContainer>
      <p>© 2024 O Bardo Produtora - Banda Gorros. Todos os direitos reservados.</p>
    </FooterContainer>
  );
}

export default Footer;

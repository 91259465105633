import React from 'react';
import styled from 'styled-components';
import GalleryComponent from '../components/GalleryComponent';

const GalleryPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const Gallery = () => {
  return (
    <GalleryPageContainer>
      <GalleryComponent />
    </GalleryPageContainer>
  );
};

export default Gallery;

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import GlobalStyle from './styles/GlobalStyle';
import HeaderDesktop from './components/header/HeaderDesktop';
import HeaderMobile from './components/header/HeaderMobile';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Gallery from './pages/Gallery';
import Contact from './pages/Contact';
import Tour from './pages/Tour';
import Rider from './pages/Rider';

const useMediaQuery = (query) => {
  const [matches, setMatches] = React.useState(window.matchMedia(query).matches);

  React.useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    media.addEventListener('change', listener);
    return () => media.removeEventListener('change', listener);
  }, [matches, query]);

  return matches;
}

function App() {
  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Router>
      <GlobalStyle />
      {isMobile ? <HeaderMobile /> : <HeaderDesktop />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/tour" element={<Tour />} />
        <Route path="/rider" element={<Rider />} />
        {/* Adicione as outras rotas aqui */}
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;

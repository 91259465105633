// src/components/Banner.js
import React from 'react';
import styled from 'styled-components';
import bannerImage from '../assets/img/logo/GORROS_05.png'; // Substitua pelo caminho da imagem da banda

const BannerContainer = styled.div`
  margin-top: 15vh;
  height: 50vh;
  width: 100vw;
  background-image: url(${bannerImage});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    padding: 0 50vw;
    margin-top: 30vh;
    height: 15vh;
    width: 40vw;
  }

`;

const Banner = () => {
  return (
    <BannerContainer>
    </BannerContainer>
  );
};

export default Banner;

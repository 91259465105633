import React from 'react';
import styled from 'styled-components';
import MapaDePalco from '../assets/docs/Mapa de Palco - BANDA GORROS.pdf';
import InputList from '../assets/docs/Input List - BANDA GORROS.pdf';
import RiderTecnico from '../assets/docs/Rider - BANDA GORROS.pdf';

const RiderContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 90vh;
  align-items: center;
  padding: 2rem;
  color: #fff;
  background: #1b1b1b;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 10vh;
  color: #fff;
`;

const Card = styled.a`
  background: #222;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 1.5rem;
  margin-bottom: 2rem;
  width: 60%;
  max-width: 600px;
  color: #ffffff;
  text-decoration: none;
  font-size: 1.2rem;
  transition: color 0.3s ease, box-shadow 0.3s ease;
  display: block;

  &:hover {
    color: #CC5500;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }
`;

const CardTitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #fff;
`;

const Rider = () => {
  return (
    <RiderContainer>
      <Title>Documentos Técnicos</Title>

      <Card href={MapaDePalco} target="_blank">
        <CardTitle>Mapa de Palco</CardTitle>
      </Card>

      {/* Repita para outros documentos */}
      
      <Card href={RiderTecnico} target="_blank">
        <CardTitle>Rider Técnico</CardTitle>
      </Card>

      <Card href={InputList} target="_blank">
        <CardTitle>Input List</CardTitle>
      </Card>

      {/* Adicione mais cards conforme necessário */}

    </RiderContainer>
  );
};

export default Rider;

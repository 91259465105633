import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import logoBanda from '../../assets/img/logo/GORROS_05.png';

const HeaderContainer = styled.header`
  background-color: #232323;
  padding: 1vh 2vw;
  text-align: center;
  position: fixed;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 769px) {
    display: none;
  }
`;

const LogoContainer = styled.div`
  padding: 0.5vh 0.5vw;
`;

const MenuIcon = styled.div`
  font-size: 1.5rem;
  margin-right: 2vh;
  cursor: pointer;
`;

const LogoBanda = styled.img`
  height: 7vh;
  width: 18vh;
`;

const MobileNav = styled.nav`
  position: absolute;
  top: 70px;
  right: 10px;
  background-color: #333;
  padding: 1rem;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  z-index: 10;

  a {
    display: block;
    margin: 10px 0;
    color: #ffffff;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
    &:hover {
      color: #CC5500;
    }
  }
`;

const HeaderMobile = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };

  // Detecta cliques fora do menu para fechá-lo
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <HeaderContainer>
      <LogoContainer>
        <Link to="/">
          <LogoBanda src={logoBanda} />
        </Link>
      </LogoContainer>

      <MenuIcon onClick={toggleMenu}>
        <FaBars color="#ffffff" />
      </MenuIcon>

      <MobileNav isOpen={menuOpen} ref={menuRef}>
        <Link to="/about" onClick={handleCloseMenu}>Sobre</Link>
        <Link to="/music" onClick={handleCloseMenu}>Músicas</Link>
        <Link to="/tour" onClick={handleCloseMenu}>Tour</Link>
        <Link to="/gallery" onClick={handleCloseMenu}>Galeria</Link>
        <Link to="/rider" onClick={handleCloseMenu}>Rider Técnico</Link>
        <Link to="/contact" onClick={handleCloseMenu}>Contato</Link>
      </MobileNav>
    </HeaderContainer>
  );
}

export default HeaderMobile;

// src/pages/Home.js
import React from 'react';
import styled from 'styled-components';
import Slider from "react-slick";
import flyerGeneralLee from '../assets/img/midia/GENERAL LEE01062016.jpg';
import flyerTainhaCore from '../assets/img/midia/09-07-2016 TAINHACORE.jpg';
import flyerBruxolico from '../assets/img/midia/20-08-2016 BRUXOLICO FEST 1.PNG';
import flyerFactory from '../assets/img/midia/08-10-2016 INAUGURAÇÃO DO FACTORY.jpg';
import flyer605 from '../assets/img/midia/05-03-2017 FESTIVAL DE ARTE 605.png';

const Flyer = styled.img`
  height: 50vh;
  max-width: 70vw;
  margin: 3vh;
  object-fit: cover;
`;

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const CarouselContainer = styled.div`
  width: 80%;
  margin-top: 10vh;
`;

const Tour = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <HomeContainer>
      <h1>Apresentações</h1>
      <CarouselContainer>
        <Slider {...settings}>
          <div>
            <h2>General Lee</h2>
            <h4>Gorros + Rinoceronte Negro</h4>
            <Flyer src={flyerGeneralLee} alt="General Lee Flyer" />
          </div>
          <div>
            <h2>TainhaCore</h2>
            <h4>Festival</h4>
            <Flyer src={flyerTainhaCore} alt="TainhaCore Flyer" />
          </div>
          <div>
            <h2>Bruxolico</h2>
            <h4>Festival Independente</h4>
            <Flyer src={flyerBruxolico} alt="Bruxolico Flyer" />
          </div>
          <div>
            <h2>Factory</h2>
            <h4>Evento</h4>
            <Flyer src={flyerFactory} alt="Factory Flyer" />
          </div>
          <div>
            <h2>Festival de Arte 605</h2>
            <h4>Mostra de música autoral</h4>
            <Flyer src={flyer605} alt="Festival de Arte 605 Flyer" />
          </div>
        </Slider>
      </CarouselContainer>
    </HomeContainer>
  );
};

export default Tour;

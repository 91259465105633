// components/MusicPlayer.js
import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faVolumeUp } from '@fortawesome/free-solid-svg-icons';

const PlayerContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #585858;
  border-radius: 8px;
  padding: 10px;
  width: 100%;
  max-width: 300px; /* Ajuste conforme necessário */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    max-width: 250px;
  }

  @media (max-width: 480px) {
    max-width: 200px;
    padding: 5px;
  }
`;

const Title = styled.p`
  font-size: 1rem;
  margin: 0;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }

  @media (max-width: 480px) {
    font-size: 0.8rem;
  }
`;

const Artist = styled.p`
  font-size: 0.9rem;
  color: #333;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }

  @media (max-width: 480px) {
    font-size: 0.7rem;
  }
`;

const AlbumCover = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 5px;
`;

const MusicInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const IconButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  color: #333;
  margin-left: 10px;
  transition: color 0.3s ease;

  &:hover {
    color: #CC5500;
  }
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  height: 5px;
  background-color: #ddd;
  border-radius: 5px;
  margin-top: 8px;
`;

const Progress = styled.div`
  height: 100%;
  width: ${(props) => props.progress}%;
  background-color: #CC5500;
  border-radius: 5px;
`;

const MusicPlayer = ({ currentSong }) => {
  const [isPlaying, setIsPlaying] = useState(false); // Estado para controle de reprodução
  const [progress, setProgress] = useState(0); // Estado para controle do progresso da música

  // Função para controlar o clique no botão de reprodução/pausa
  const togglePlay = () => {
    setIsPlaying(!isPlaying);
    // Lógica para controlar a reprodução da música
  };

  // Função para controlar a mudança no progresso da música
  const handleProgressChange = (e) => {
    const newProgress = parseInt(e.target.value, 10);
    setProgress(newProgress);
    // Lógica para ajustar o progresso da música
  };

  return (
    <PlayerContainer>
      {currentSong && (
        <>
          <AlbumCover src={currentSong.albumCover} alt="Album Cover" />
          <MusicInfo>
            <Title>{currentSong.title}</Title>
            <Artist>{currentSong.artist}</Artist>
            <ProgressBarContainer>
              <Progress progress={progress}></Progress>
            </ProgressBarContainer>
          </MusicInfo>
          <Controls>
            <IconButton onClick={togglePlay}>
              <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
            </IconButton>
            <IconButton>
              <FontAwesomeIcon icon={faVolumeUp} />
            </IconButton>
          </Controls>
        </>
      )}
    </PlayerContainer>
  );
};

export default MusicPlayer;

// src/pages/Contact.js
import React from 'react';
import styled from 'styled-components';

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  color: #fff;
  background-color: #1a1a1a;
  min-height: 100vh;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const ContactInfo = styled.div`
  max-width: 800px;
  width: 100%;
  text-align: center;
  margin: 2rem 0;
  background-color: #2b2b2b;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  a {
    color: #CC5500;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
    
    &:hover {
      color: #ff7b00;
    }
  }

  p {
    margin: 0.5rem 0;
  }

  h2 {
    margin-top: 1.5rem;
  }
  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #CC5500;
`;

const Subtitle = styled.h2`
  font-size: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: #fff;
`;

const Paragraph = styled.p`
  font-size: 1rem;
  color: #ccc;
`;

const Contact = () => {
  return (
    <ContactContainer>
      <Title>Contato</Title>
      <ContactInfo>
        <Subtitle>Banda Gorros:</Subtitle>
        <Paragraph>
          <a href="https://www.facebook.com/bandagorros" target="_blank" rel="noopener noreferrer">
            Facebook
          </a>
        </Paragraph>
        <Paragraph>
          <a href="https://www.instagram.com/bandagorros/" target="_blank" rel="noopener noreferrer">
            Instagram
          </a>
        </Paragraph>
        <Paragraph>
          <a href="https://www.youtube.com/@bandagorros/videos" target="_blank" rel="noopener noreferrer">
            YouTube
          </a>
        </Paragraph>

        <Subtitle>Produtora:</Subtitle>
        <Paragraph>
          <a href="http://www.obardoprodutora.com.br" target="_blank" rel="noopener noreferrer">
            O Bardo Produtora
          </a>
        </Paragraph>

        <Subtitle>Contato para shows:</Subtitle>
        <Paragraph>
          <a href="https://wa.me/5541996259946" target="_blank" rel="noopener noreferrer">
            (41) 99625-9946 - Carlos Félix
          </a>
        </Paragraph>
        <Paragraph>
          <a href="https://wa.me/5548991688900" target="_blank" rel="noopener noreferrer">
            (48) 99168-8900 - Lucas Corteletti
          </a>
        </Paragraph>

        <Subtitle>Email:</Subtitle>
        <Paragraph>bandagorros@gmail.com</Paragraph>
      </ContactInfo>
    </ContactContainer>
  );
};

export default Contact;
